<client-header
  [headerLabel]="'Features'"
  (click)="headerGroups.features = !headerGroups.features"
></client-header>

<div class="collapse-container" *ngIf="headerGroups.features">
  <div *ngIf="!client.feed_enabled">
    <app-radio-btn
      [btnLabel]="'Go to Feed'"
      [btnWidth]="'460px'"
      [btnName]="'go-to-feed'"
      [(btnModel)]="productGroup.show_back_to_feed"
      [tiptext]="
        'Turning on this feature will enable customers to navigate back to their feed from the message details and message branch views.'
      "
    >
    </app-radio-btn>
  </div>

  <div>
    <app-radio-btn
      [btnLabel]="client.feed_enabled ? 'Hide Feed Menu' : 'Hide Wire Menu'"
      [btnWidth]="'460px'"
      [btnName]="'hide-wire-menu'"
      [(btnModel)]="productGroup.hide_hamburger"
      [tiptext]="
        'Turning on this feature will hide the hamburger menu from the message details and client feed views.'
      "
    >
    </app-radio-btn>
  </div>

  <div *ngIf="!client.feed_enabled">
    <app-radio-btn
      [btnLabel]="'Hide Product Group From Menu'"
      [btnWidth]="'460px'"
      [btnName]="'hide-product-group'"
      [(btnModel)]="productGroup.hide_pg_from_hamburger"
      [tiptext]="
        'Turning on this feature will hide the product group from the hamburger menu.'
      "
    >
    </app-radio-btn>
  </div>

  <div *ngIf="!!productGroup.password">
    <app-radio-btn
      [btnLabel]="'Password Required'"
      [btnWidth]="'460px'"
      [btnName]="'password-required'"
      [(btnModel)]="productGroup.password.enabled"
    >
    </app-radio-btn>

    <div class="pwd-req" [ngClass]="{ hide: !productGroup.password.enabled }">
      <div class="messaging-panel arrow-left-center">
        <div class="card panel-default">
          <div class="card-header">
            <div class="col-sm-12">
              Password
              {{ this.client.feed_enabled ? 'Page Content' : 'Required' }}
            </div>
          </div>
          <div class="card-body">
            <form [formGroup]="passwordForm">
              <div class="form-group">
                <div>
                  <label>Set Password Text</label>
                </div>
                <div style="display: flex">
                  <rn-form-input-state-control
                    [formReference]="passwordForm.controls['text']"
                    [isError]="passwordHasTextError()"
                    [showCharacterCount]="this.client.feed_enabled"
                    [totalLength]="getCharacterLimit('setPasswordText')"
                  >
                    <ng-container>
                      <app-tiny-editor
                        class="tiny-editor-mentions"
                        [elementId]="'tiny-editor-password-text'"
                        [formControl]="passwordForm.controls['text']"
                      ></app-tiny-editor>
                    </ng-container>
                  </rn-form-input-state-control>
                </div>
              </div>

              <div class="form-group">
                <div>
                  <label>Submit button label</label>
                </div>
                <div style="display: flex">
                  <rn-form-input-state-control
                    [formReference]="passwordForm.controls['label']"
                    [showCharacterCount]="this.client.feed_enabled"
                    [totalLength]="
                      getCharacterLimit('setPasswordSubmitButtonLabel')
                    "
                  >
                    <ng-container>
                      <input
                        name="button_label"
                        class="form-control button_label"
                        [(ngModel)]="setPasswordSubmitButtonDefaultText"
                        [formControl]="passwordForm.controls['label']"
                      />
                    </ng-container>
                  </rn-form-input-state-control>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ng-container
        *ngIf="client.feed_enabled; then newFeedEnabled; else newFeedDisabled"
      >
      </ng-container>

      <ng-template #newFeedEnabled>
        <generic-phone [overflowY]="'auto'">
          <set-password-page-preview-react
            [pswMessageText]="productGroup.password.text"
            [pswButtonLabel]="productGroup.password.label"
            [pswTCS]="productGroup.password.tcs"
            [pswTCMandatory]="productGroup.password.tc_mandatory"
            [logoUrl]="productGroup.branding.icon_s3_url"
            [color]="productGroup.branding.color"
            [productGroupName]="productGroup.name"
            [showProductGroup]="!productGroup.hide_pg_wire_display_name"
            [productGroupDescription]="productGroup.description"
          ></set-password-page-preview-react>
        </generic-phone>
      </ng-template>

      <ng-template #newFeedDisabled>
        <generic-phone>
          <app-password-preview
            [productGroup]="productGroup"
            [client]="client"
          ></app-password-preview>
        </generic-phone>
      </ng-template>
    </div>

    <label class="inwire-consent"
      >In {{ client.feed_enabled ? 'Feed' : 'Wire' }} Consent</label
    >
    <app-radio-btn
      [btnLabel]="'Message Builder'"
      [btnWidth]="'460px'"
      [btnName]="'iwcu-message-builder'"
      [(btnModel)]="productGroup.consent.in_wire_upgrade.enabled"
      [labelIndentWidth]="15"
    ></app-radio-btn>
    <app-radio-btn
      *ngIf="!client.feed_enabled"
      [btnLabel]="'Password page'"
      [btnWidth]="'460px'"
      [btnName]="'iwcu-password-page'"
      [(btnModel)]="
        productGroup.consent.in_wire_upgrade.enabled_on_set_password
      "
      [disabled]="!productGroup.password.enabled"
      [tiptext]="
        !productGroup.password.enabled
          ? 'Password must be enabled to configure this feature.'
          : null
      "
      [labelIndentWidth]="15"
    ></app-radio-btn>

    <form
      class="input-container"
      [formGroup]="iwcuForm"
      [ngClass]="{ hide: !showTC() }"
    >
      <div [ngClass]="{ 'has-error': iwcuHasFormError() }">
        <label>{{
          client.feed_enabled
            ? 'Consent Upgrade Ts&Cs'
            : 'In Wire Consent Upgrade Ts&Cs'
        }}</label>
        <span
          class="error-msg"
          *ngIf="iwcuHasFormError()"
          tooltip="This field is required"
          placement="bottom"
        >
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </span>
        <app-tiny-editor
          class="tiny-editor-mentions"
          [elementId]="'tiny-editor-in-wire-upgrade'"
          [formControl]="iwcuForm.controls['ts_cs']"
          i18n-placeholder="
            Placeholder text for in {{client.feed_enabled ? 'feed' : 'wire'}}
            consent upgrade terms and conditions
          "
        >
        </app-tiny-editor>

        <p class="tip-text">
          <svg width="15" height="65" style="float: left">
            <polyline
              points="8,0 8,24, 2,30 8,36 8,105"
              style="fill: none; stroke: #5b5a59; stroke-width: 1"
            />
          </svg>
          <span
            >Terms and Conditions that will be shown to a customer when offering
            In {{ client.feed_enabled ? 'Feed' : 'Wire' }} Consent
            Upgrade.</span
          >
        </p>
      </div>
    </form>
  </div>
</div>
