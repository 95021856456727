import { BlackoutWindow } from '@app/core/models/blackout-window';
import { Conversions } from '../utils/conversions';
import { Action } from '@app/core/models/action';
import { MediaAsset } from './media';

export abstract class ComponentBaseClass {
  type: string;
  name: string;
  to: string;
  notes: string;
  nickname: string;
  step: string;
  family: string;
  showAction: boolean;

  constructor() {
    this.name = this.createGuid();
  }

  createGuid() {
    let d = new Date().getTime();
    if (
      typeof performance !== 'undefined' &&
      typeof performance.now === 'function'
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  }
}

export class WorkflowClass extends ComponentBaseClass {
  labels: string[];
  client_trigger_id: string;

  static deserialize(input: WorkflowClass) {
    const component = new WorkflowClass(input.type, input.to);
    component.name = input.name;
    component.notes = input.notes;
    component.family = input.family;
    component.nickname = input.nickname;
    component.step = input.step;
    component.client_trigger_id = input.client_trigger_id;
    component.labels = input['labels'] || component.labels;
    return component;
  }

  constructor(type: string, to) {
    super();
    this.step = '1';
    this.type = type;
    this.to = to;
    if (this.type === 'APITrigger') {
      this.nickname = '';
      this.family = this.name;
    }
    this.labels = [];
  }
}

export class MessageClass extends ComponentBaseClass {
  message_type: string;
  content_type: string;
  parent: string;
  wire: WireClass;
  sms: SmsClass;
  date_created: Date;
  date_modified: Date;
  client_message_tag?: string;
  client_message_id?: string;
  outcome_id?: string;
  outcome_name?: string;
  experience_type_id?: string;
  experience_type_name?: string;
  message_level_branding?: MessageLevelBrandingClass;
  message_level_branding_text?: string;
  message_expiration?: MessageExpirationClass;

  static deserialize(input: MessageClass) {
    const message = new MessageClass({ showSmsByDefault: true }); // showSmsByDefault related values will be overwritten by the SmsClass.deserialize call below
    message.type = input.type;
    message.name = input.name;
    message.nickname = input.nickname;
    message.to = input.to;
    message.date_created = input.date_created;
    message.date_modified = input.date_modified;
    message.message_type = input.message_type;
    message.content_type = input.content_type;
    message.notes = input.notes;
    message.parent = input.parent;
    message.step = input.step;
    message.client_message_tag =
      input.client_message_tag || input.client_message_id; // OR clause is a temporary fix until the data is modified to only use the _tag key
    message.outcome_id = input.outcome_id;
    message.outcome_name = input.outcome_name;
    message.experience_type_id = input.experience_type_id;
    message.experience_type_name = input.experience_type_name;
    message.message_level_branding = input.message_level_branding;
    message.message_expiration = input.message_expiration;

    if (input.wire) {
      message.wire = WireClass.deserialize(input.wire);
    }
    if (input.sms) {
      message.sms = SmsClass.deserialize(input.sms, input.type);
    }
    return message;
  }

  constructor({ showSmsByDefault }: { showSmsByDefault: boolean }) {
    super();
    this.step = '1.a';
    this.nickname = 'New Message';
    this.type = 'MessageSender';
    this.message_type = 'content';
    this.wire = new WireClass();
    this.sms = new SmsClass({ showSmsByDefault: showSmsByDefault });
    this.date_created = new Date();
    this.date_modified = this.date_created;
    this.outcome_id = null;
    this.experience_type_id = null;
    this.experience_type_name = '';
    this.outcome_name = '';
    this.message_level_branding = null;
    this.message_expiration = null;
  }
  get isNew() {
    // Test whether this message is brand new (i.e. user hasn't touched yet);
    return (
      this.wire.media_id === undefined &&
      this.wire.text === '' &&
      this.wire.is_shown === 'true'
    );
  }

  hasFormActions(): boolean {
    return this.formActions().length > 0;
  }

  /* Returns all actions of the "form_info_capture" type */
  formActions(): Action[] {
    return this.wire.actions.filter(
      (action) => action.type === 'form_info_capture',
    );
  }

  serialize(): Object {
    const obj = JSON.parse(JSON.stringify(this));
    obj['sms'] = this.sms.serialize();
    obj['wire'] = this.wire.serialize();
    return obj;
  }
}

export class MessageDelayerClass extends ComponentBaseClass {
  message_type: string;
  content_type: string;
  date_created: Date;
  date_modified: Date;
  interval_value: number;
  interval_type: string;

  static deserialize(input: MessageDelayerClass) {
    const delayer = new MessageDelayerClass();
    delayer.type = input.type;
    delayer.name = input.name;
    delayer.to = input.to;
    delayer.interval_value = input.interval_value;
    delayer.interval_type = input.interval_type;
    delayer.notes = input.notes;
    delayer.step = input.step;
    return delayer;
  }

  constructor() {
    super();
    this.type = 'WorkflowDelayer';
    this.interval_value = 1;
    this.interval_type = 'Day';
  }
}

export class WireClass {
  text: string = '';
  alternate_text: string = '';
  product_group = 'default';
  message_text_display: string;
  is_shown: string = 'true';
  is_alternate_message: boolean = false;
  branding: string = null;
  media_type: string;
  media_id: string;
  media_asset?: MediaAsset;
  image_url: string;
  video_url: string;
  actions: any[] = [];
  show_banner: boolean = false;

  static deserialize(input: WireClass) {
    const wire = new WireClass();
    wire.text = input.text;
    wire.alternate_text = input.alternate_text;
    if (input.branding) {
      wire.branding = input.branding;
    }
    wire.message_text_display = input.message_text_display;
    wire.media_type = input.media_type;
    wire.media_id = input.media_id;
    wire.media_asset = input.media_asset
      ? MediaAsset.deserialize(input.media_asset)
      : undefined;
    wire.actions = input.actions;
    wire.is_shown = Conversions.toString(input.is_shown);
    wire.is_alternate_message = input.is_alternate_message;
    wire.product_group = input.product_group || 'default';
    wire.show_banner = input.show_banner ?? false;
    return wire;
  }

  constructor() {}

  serialize(): Object {
    const obj = JSON.parse(JSON.stringify(this));
    // Convert strings with 'true' or 'false' to actual booleans.
    obj['video_url'] = undefined;
    obj['image_url'] = undefined;
    obj['is_shown'] = this.is_shown === 'true';
    obj['show_banner'] = this.show_banner;
    return obj;
  }
}

export class SmsClass {
  is_shown: string;
  is_alternate_message: boolean;
  auth_link: string;
  text: string;
  body: string;
  disclaimer: string;
  alternate_text: string;
  blackout_settings: BlackoutSettings;

  static deserialize(input: SmsClass, type: string) {
    const sms = new SmsClass({ showSmsByDefault: true }); // overwritten below
    sms.text = input.text;
    sms.alternate_text = input.alternate_text;
    sms.disclaimer = input.disclaimer || '';
    sms.body = input.body || '';
    sms.auth_link = input.auth_link || '';
    if (type === 'MessageResponse') {
      sms.is_shown = 'false';
      sms.is_alternate_message = false;
    } else {
      sms.is_shown = Conversions.toString(input.is_shown);
      sms.is_alternate_message = input.is_alternate_message
        ? input.is_alternate_message
        : false;
    }

    if (input.blackout_settings) {
      sms.blackout_settings = new BlackoutSettings().deserialize(
        input.blackout_settings,
      );
    } else {
      sms.blackout_settings = new BlackoutSettings();
    }
    return sms;
  }

  constructor({ showSmsByDefault }: { showSmsByDefault: boolean }) {
    this.is_shown = Conversions.toString(showSmsByDefault);
    this.text = '';
    this.body = '';
    this.auth_link = '@{auth-link-experience-on-feed}';
    this.disclaimer = 'Text help or stop. Msg&DataRatesMayApply';
    this.blackout_settings = new BlackoutSettings();
  }

  serialize(): Object {
    const obj = JSON.parse(JSON.stringify(this));
    // Convert strings with 'true' or 'false' to actual booleans.
    obj['is_shown'] = this.is_shown === 'true';
    return obj;
  }
}

export class MessageLevelBrandingClass {
  branding_logo: string;
  brand_name: string;
  hex_code: string;
  timestamp: string;
  branding_id: string;

  static deserialize(input: MessageLevelBrandingClass, type: string) {
    const messageLevelBranding = new MessageLevelBrandingClass();
    messageLevelBranding.brand_name = input.brand_name;
    messageLevelBranding.branding_logo = input.branding_logo;
    messageLevelBranding.hex_code = input.hex_code;
    messageLevelBranding.timestamp = input.timestamp;
    messageLevelBranding.branding_id = input.branding_id;
    return messageLevelBranding;
  }

  constructor() {
    this.branding_logo = '';
    this.brand_name = '';
    this.hex_code = '';
    this.timestamp = '';
    this.branding_id = '';
  }
}
export class MessageExpirationClass {
  unit: 'days' | 'weeks' | null;
  amount: number | null;
  type: 'default' | 'none' | 'custom' | null;

  static deserialize(input: MessageExpirationClass, type: string) {
    const messageExpiration = new MessageExpirationClass();
    messageExpiration.unit = input.unit;
    messageExpiration.amount = input.amount;
    messageExpiration.type = input.type;
    return messageExpiration;
  }

  constructor() {
    this.unit = null;
    this.amount = null;
    this.type = null;
  }
}

export class SmsLinkTypes {
  feed_experience: SmsLinkType = new SmsLinkType(
    'Feed Experience',
    'experience-on-feed',
    '@{auth-link-experience-on-feed}',
    { requiresFeedMessage: true },
  );
  top_of_feed: SmsLinkType = new SmsLinkType(
    'Top Of Feed',
    'top-of-feed',
    '@{auth-link-top-of-feed}',
  );
  custom_tracked_url: SmsLinkType = new SmsLinkType(
    'Custom Tracked URL',
    'custom-tracked-url',
    '@{custom-tracked-url}',
    {
      custom_input_field: '@{input_url}',
      featureFlag: 'custom-tracked-url',
      requires_wire_only: true,
    },
  );
  none: SmsLinkType = new SmsLinkType('None', 'none', '');
  url: SmsLinkType = new SmsLinkType('URL', 'url', 'my.relayit.com');
  details: SmsLinkType = new SmsLinkType(
    'Message Details',
    'message-details',
    '@{auth-link}',
    { requiresFeedMessage: true },
  );
}

export class SmsLinkType {
  display_text: string;
  value: string;
  auth_link: string;
  feature_flag: string;
  requires_feed_message: boolean;
  custom_input_field: string;
  requires_wire_only: boolean;

  constructor(
    displayText: string,
    value: string,
    authLink: string,
    options?: {
      featureFlag?: string;
      requiresFeedMessage?: boolean;
      custom_input_field?: string;
      requires_wire_only?: boolean;
    },
  ) {
    this.display_text = displayText;
    this.value = value;
    this.auth_link = authLink;
    this.feature_flag = options?.featureFlag ?? '';
    this.requires_feed_message = options?.requiresFeedMessage ?? false;
    this.custom_input_field = options?.custom_input_field ?? '';
    this.requires_wire_only = options?.requires_wire_only ?? false;
  }
}

export class BlackoutSettings {
  blackout_type: 'default' | 'none' | 'custom';
  blackout_window: BlackoutWindow;

  constructor(blackoutWindow?: BlackoutWindow) {
    this.blackout_type = 'default';
    this.blackout_window = blackoutWindow || null; // It's the component's job to set this to client defaults
  }

  deserialize(input: BlackoutSettings) {
    const blackoutSettings = new BlackoutSettings();
    blackoutSettings.blackout_type = input.blackout_type;
    if (input.blackout_window === null) {
      input.blackout_window = new BlackoutWindow();
    }
    blackoutSettings.blackout_window = BlackoutWindow.deserialize(
      input.blackout_window,
    );
    return blackoutSettings;
  }

  hasBlackoutWindow(): boolean {
    return this.blackout_window != null;
  }
}
