import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioBtnComponent } from '@app/shared/radio-btn/radio-btn.component';
import { Client, ProductGroup } from '@app/core/models/client';
import { ClientService } from '@app/core/services/client.service';
import { TitleService } from '@app/core/services/title.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { flatMap, switchMap } from 'rxjs/operators';
import { VerificationComponent } from '@app/client-config/product-group/components/verification/verification.component';

@Component({
  selector: 'app-product-group-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class ProductGroupOnboardingComponent implements OnInit, OnDestroy {

  @ViewChild(RadioBtnComponent) radioBtn;
  @ViewChild(MessageDialogComponent, { static: true }) messageDialog: MessageDialogComponent;
  @ViewChild('verificationForm') verificationForm: VerificationComponent;

  client: Client;
  productGroup: ProductGroup;
  productGroupID: string;
  isSignUpValid: boolean;
  isVerificationValid: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private clientService: ClientService,
              private router: Router,
              private titleService: TitleService) {
  }

  ngOnInit() {
    this.getClientAndProductGroup();
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  cancelCurrent(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/product-group/${this.productGroupID}/legal`);
  }

  getClientAndProductGroup(): void {
    this.productGroupID = this.activatedRoute.snapshot.params['productId'];
    this.clientService.getClient(this.activatedRoute.snapshot.params['clientId']).pipe(
      flatMap((client: Client) => {
        this.client = client;
        return this.clientService.getProductGroup(this.client.id, this.productGroupID);
      })
    ).subscribe(
      (productGroup: ProductGroup) => this.productGroup = productGroup,
      (error) => this.messageDialog.showMessage('Product group retrieval failed.'),
      () => this.setPrimaryTitle()
    );
  }

  validSignUpForm(event: boolean): void {
    this.isSignUpValid = event;
  }

  validVerificationForm(event: boolean): void {
    this.isVerificationValid = event;
  }

  isValidForm(): boolean {
    return this.validSignUp()
      && ProductGroup.validPasswordRequired(this.productGroup)
      && ProductGroup.validInWireConsent(this.productGroup);
  }

  updateModels(): void {
    if (this.verificationForm) {
      this.productGroup.onboarding.verification = this.verificationForm.getVerificationConfig();
      this.client.verification.fields = this.verificationForm.getVerificationFields();
    }
  }

  saveProduct(): void {
    this.updateModels();
    this.clientService.updateClient(this.client).pipe(
      switchMap((client) => {
        return this.clientService.updateProductGroup(this.client.id, this.productGroup);
      })
    ).subscribe(
      () => this.router.navigateByUrl(`/client/${this.client.id}/product-group`),
      err => {
        const serverMessage = this.clientService.cleanseError(err);
        this.messageDialog.showMessage(`Product group save failed${serverMessage}`);
      }
    );
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Edit Product Group - ' + this.client.company_name
        : 'Edit Product Group');
  }

  private validSignUp(): boolean {
    if (this.productGroup.onboarding.sign_up_page.enabled === 'true') {
      return this.isSignUpValid
        && this.productGroup.consent.express_written_consent_ts_cs?.length > 0;
    } else if (this.client.verification.enabled === 'true') {
      return this.isVerificationValid;
    } else {
      return true;
    }
  }
}
