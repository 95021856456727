import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppWrapperComponent } from '@app/app-wrapper.component';
import { ClientConfigRoutes } from '@app/client-config/client-config-routes';
import { MediaLibraryRoutes } from '@app/media-library/media-library-routes';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FileLauncherPageComponent } from '@app/file-launcher/components/file-launcher-page/file-launcher-page.component';
import { JobsListComponent } from '@app/jobs-list/components/jobs-list/jobs-list.component';
import { SftpMgmtComponent } from '@app/sftp-mgmt/components/sftp-mgmt/sftp-mgmt.component';
import { ShortcodeMgmtComponent } from '@app/shortcode-mgmt/shortcode-mgmt-component/shortcode-mgmt.component';
import { TwoWayRoutes } from '@app/two-way/two-way-routes';
import { ApiManagementRoutes } from './api-mgmt/api-mgmt-routes';
import { Permissions } from './core/services/permission.service';
import { CustomerDetailComponent } from './customer/customer-detail/customer-detail.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CxBuilderComponent } from './cx-builder/cx-builder.component';
import { JourneyBuilderComponent } from './cx-builder/journey-builder/journey-builder.component';
import { JourneyCopyComponent } from './cx-builder/journey-copy/journey-copy.component';
import { JourneyMetadataPageComponent } from './cx-builder/journey-metadata-page/journey-metadata-page.component';
import { MessageBuilderComponent } from './cx-builder/message-builder/message-builder.component';
import { MessageCopyComponent } from './cx-builder/message-copy/message-copy.component';
import { AllJourneysComponent } from './journey-list/all-journeys/all-journeys.component';
import { LauncherListComponent } from './launcher/launcher-list/launcher-list.component';
import { QuickLaunchComponent } from './launcher/quick-launch/quick-launch.component';
import { TestLauncherComponent } from './launcher/test-launcher/test-launcher.component';
import { WorkflowLauncherComponent } from './launcher/workflow-launcher/workflow-launcher.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './legal/terms-and-conditions/terms-and-conditions.component';
import { JourneyPdfPreviewComponent } from './preview/journey-pdf-preview/journey-pdf-preview.component';
import { JourneyPreviewComponent } from './preview/journey-preview/journey-preview.component';
import { AuthGuardService } from './security/auth-guard.service';
import { ForgotPasswordComponent } from './security/forgot-password/forgot-password.component';
import { KeyConfigComponent } from './security/key-config/key-config.component';
import { LoginGuardService } from './security/login-guard.service';
import { LoginComponent } from './security/login/login.component';
import { LogoutComponent } from './security/logout/logout.component';
import { ResetPasswordComponent } from './security/reset-password/reset-password.component';
import { SafeRedirectComponent } from './security/safe-redirect/safe-redirect.component';
import { UserEditPageComponent } from './user/user-edit-page/user-edit-page.component';
import { UserListPageComponent } from './user/user-list-page/user-list-page.component';
import { NotificationAdminComponent } from './notification-admin/notification-admin-component';
import { JourneyPdfExpandPreviewComponent } from './preview/journey-pdf-expand-preview/journey-pdf-expand-preview.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/safe-redirect',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AppWrapperComponent,
    children: [
      ClientConfigRoutes[0],
      MediaLibraryRoutes[0],
      TwoWayRoutes[0],
      TwoWayRoutes[1],
      TwoWayRoutes[2],
      TwoWayRoutes[3],
      TwoWayRoutes[4],
      ApiManagementRoutes[0],
      {
        path: 'experiences',
        component: AllJourneysComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_list,
        },
      },
      {
        path: 'journeys',
        redirectTo: 'experiences',
      },
      {
        path: 'launcher',
        component: LauncherListComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_launcher,
        },
      },
      {
        path: 'launcher/launch/:journeyId',
        component: WorkflowLauncherComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_launcher,
        },
      },
      {
        path: 'launcher/test/:journeyId',
        component: TestLauncherComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_launcher,
        },
      },
      {
        path: 'launcher/launchforuser/:ccid',
        component: TestLauncherComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_launcher,
        },
      },
      {
        path: 'launcher/launchforuser/:ccid/launch/:journeyId',
        component: TestLauncherComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_launcher,
        },
      },
      {
        path: 'cx-builder',
        component: CxBuilderComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_journey_builder,
        },
      },
      {
        path: 'cx-builder/experience-builder',
        component: JourneyBuilderComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_create,
        },
      },
      {
        path: 'cx-builder/journey-builder',
        redirectTo: 'cx-builder/experience-builder',
      },
      {
        path: 'cx-builder/experience-builder/:journeyId',
        component: JourneyBuilderComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_edit,
        },
      },
      {
        path: 'cx-builder/journey-builder/:journeyId',
        redirectTo: 'cx-builder/experience-builder/:journeyId',
      },
      {
        path: 'cx-builder/experience-metadata',
        component: JourneyMetadataPageComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_create,
        },
      },
      {
        path: 'cx-builder/journey-metadata',
        redirectTo: 'cx-builder/experience-metadata',
      },
      {
        path: 'cx-builder/experience-copy/experience-library',
        component: JourneyCopyComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.exp_lib_experiences,
          type: 'template',
        },
      },
      {
        path: 'cx-builder/experience-copy',
        component: JourneyCopyComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_create,
        },
      },
      {
        path: 'cx-builder/experience-import',
        component: JourneyCopyComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_create,
          type: 'import',
        },
      },
      {
        path: 'cx-builder/journey-copy',
        redirectTo: 'cx-builder/experience-copy',
      },
      {
        path: 'cx-builder/message-builder/:journeyId/message/:messageId',
        component: MessageBuilderComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_edit,
        },
      },
      {
        path: 'cx-builder/message-copy/:targetType/:journeyId/:messageId',
        component: MessageCopyComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.journey_edit,
        },
      },
      {
        path: 'file-engine-jobs',
        component: JobsListComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.jobs,
          flag: FeatureFlags.file_engine_jobs_list,
        },
      },
      {
        path: 'file-engine-jobs/upload',
        component: FileLauncherPageComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.jobs_add,
          flag: FeatureFlags.file_engine_jobs_list,
        },
      },
      {
        path: 'experience-library',
        loadChildren: () =>
          import('@app/experience-library/experience-library.module').then(
            (m) => m.ExperienceLibraryModule,
          ),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.exp_lib_experiences,
          flag: FeatureFlags.experience_library,
        },
      },
      {
        path: 'report-configuration',
        loadChildren: () =>
          import('@app/report-configuration/report-configuration.module').then(
            (m) => m.ReportConfigurationModule,
          ),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.reporting_configuration_read,
          flag: FeatureFlags.report_inventory,
        },
      },
      {
        path: 'relay-iq',
        loadChildren: () =>
          import('@app/relay-iq/relay-iq.module').then((m) => m.RelayIQModule),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.relay_iq_admin,
          flag: FeatureFlags.relay_iq_admin,
        },
      },
      {
        path: 't2e-mgmt',
        loadChildren: () =>
          import('@app/t2e-mgmt/t2e-mgmt.module').then((m) => m.T2EMgmtModule),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.user,
          flag: FeatureFlags.t2e_mgmt,
        },
      },
      {
        path: 'data-and-reporting',
        loadChildren: () =>
          import('@app/data-and-reporting/data-and-reporting.module').then(
            (m) => m.DataAndReportingModule,
          ),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_performance,
        },
      },
      {
        path: 'customer-lookup/:customerId',
        component: CustomerDetailComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.customer_detail,
        },
      },
      {
        path: 'customer-lookup',
        component: CustomerListComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_customer,
        },
      },
      {
        path: 'user-mgmt',
        component: UserListPageComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_user_mgmt,
        },
      },
      {
        path: 'user-mgmt/edit/:userID',
        component: UserEditPageComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_user_mgmt,
        },
      },
      {
        path: 'user-mgmt/new',
        component: UserEditPageComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_user_mgmt,
        },
      },
      {
        path: 'key-config',
        component: KeyConfigComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.roc_gpg_edit,
        },
      },
      {
        path: 'sftp-mgmt',
        component: SftpMgmtComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_sftp_mgmt,
        },
      },
      {
        path: 'shortcode-mgmt',
        component: ShortcodeMgmtComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.client_create,
        },
      },
      {
        path: 'terms',
        component: TermsAndConditionsComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.all,
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.all,
        },
      },
      {
        path: 'onboarding',
        component: QuickLaunchComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.ui_quick_launch,
        },
      },
      {
        path: 'sso',
        loadChildren: () => import('./sso/sso.module').then((m) => m.SsoModule),
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.sso_all,
          flag: FeatureFlags.sso_self_service,
        },
      },
      {
        path: 'notification-admin',
        component: NotificationAdminComponent,
        canActivate: [AuthGuardService],
        data: {
          perm: Permissions.all,
        },
      },
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'login/:clientId',
    component: LoginComponent,
    canActivate: [LoginGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'safe-redirect',
    component: SafeRedirectComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'preview/:journeyId',
    component: JourneyPreviewComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'pdf-preview-expanded/:journeyId',
    component: JourneyPdfExpandPreviewComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
      flag: FeatureFlags.expanded_pdf_view,
    },
  },
  {
    path: 'pdf-preview/:journeyId',
    component: JourneyPdfPreviewComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'reset-password/:auth-token',
    component: ResetPasswordComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [AuthGuardService],
    data: {
      perm: Permissions.all,
    },
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
