import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client } from '@app/core/models/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientService } from '@app/core/services/client.service';
import { MessageDialogComponent } from '@app/shared/message-dialog/message-dialog.component';
import { TitleService } from '@app/core/services/title.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FeatureService } from '@app/core/services/feature.service';
import { FeatureFlags } from '@app/core/models/feature-flags';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-client-images-new',
  templateUrl: './client-images-new.component.html',
  styleUrls: ['./client-images-new.component.scss'],
})
export class ClientImagesNewComponent implements OnInit, OnDestroy {
  @ViewChild(MessageDialogComponent, { static: true })
  messageDialog: MessageDialogComponent;
  @ViewChild('toggleOnFeedWarning', { static: true })
  toggleOnFeedWarningDialog: MessageDialogComponent;
  @ViewChild('toggleOffFeedWarning', { static: true })
  toggleOffFeedWarningDialog: MessageDialogComponent;
  selectedDaysWeeks: UntypedFormControl = new UntypedFormControl(
    'Days',
    Validators.required,
  );
  amountOfDaysWeeks: FormControl<number> = new FormControl<number>(
    null,
    Validators.required,
  );
  showTooltip: boolean = false;
  daysWeeks = ['Days', 'Weeks'];
  client: Client;
  message_expiration: {};
  headerGroups = { portalImages: true, clientID: true };
  featureFlags = FeatureFlags;

  constructor(
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private featureService: FeatureService,
    private titleService: TitleService,
    private router: Router,
  ) {
    this.client = new Client();
    this.getClient();
  }

  ngOnInit() {
    this.selectedDaysWeeks = new UntypedFormControl(
      'Days',
      Validators.required,
    );
    this.amountOfDaysWeeks = new FormControl<number>(null, Validators.required);
    this.selectedDaysWeeks.valueChanges.subscribe((value) => {
      this.client.message_expiration.unit = value.toLowerCase();
    });
    this.amountOfDaysWeeks.valueChanges.subscribe((amountValue) => {
      this.client.message_expiration.amount = amountValue;
    });
  }

  ngOnDestroy() {
    this.titleService.deactivate();
  }

  toggleHeader(headerGroup: string) {
    if (this.headerGroups[headerGroup] !== undefined) {
      this.headerGroups[headerGroup] = !this.headerGroups[headerGroup];
    }
  }

  /**
   * Returns the client's 'New Feed' setting
   * Defaults to 'false' if not previously set
   */
  get newFeedEnabled(): boolean {
    return this.client.feed_enabled;
  }

  /**
   * Called when client clicks the 'New Feed' switch or when user click "Cancel" on pop-up warning
   * Toggles 'off' to 'on' and vice versa
   * Case 1 "User clicks toggle" : The toggle switches and a pop-up warning appears to confirm if user want to update
   * Case 2 "User click Cancel on pop-up": the toggle switches back to its prior state before the user clicks the toggle.
   */
  toggleNewFeedEnabled(backToPriorState: boolean = false): void {
    if (backToPriorState) {
      this.client.feed_enabled = !this.client.feed_enabled;
    } else {
      this.client.feed_enabled = !this.client.feed_enabled;
      this.displayWarningToggleNewFeed(this.client.feed_enabled);
    }
  }

  /**
   * Called when user click Enable/Disable on toggle-feed pop-up
   */
  closeToggleFeedDialog() {
    this.toggleOffFeedWarningDialog.close();
    this.toggleOnFeedWarningDialog.close();
  }

  displayWarningToggleNewFeed(isFeedTurningOn: boolean): void {
    if (isFeedTurningOn) {
      this.toggleOnFeedWarningDialog.showMessage();
    } else {
      this.toggleOffFeedWarningDialog.showMessage();
    }
  }

  /**
   * Determines if the "New Feed" toggle is visible to the user
   */
  get newFeedFeatureFlagOn(): boolean {
    return this.featureService.checkFlag(this.featureFlags.new_feed);
  }

  get feedExpiredMessagesFlagOn(): boolean {
    return this.featureService.checkFlag(
      this.featureFlags.feed_expired_messages,
    );
  }

  get expiredMessagesEnabled(): boolean {
    return this.client.message_expiration.enabled;
  }

  toggleExpiredMessagesEnabled(): void {
    this.client.message_expiration.enabled =
      !this.client.message_expiration.enabled;
  }

  determineSaveButtonDisabled(): boolean {
    if (this.expiredMessagesEnabled && !this.amountOfDaysWeeks.valid) {
      return true;
    } else if (this.expiredMessagesEnabled && !this.selectedDaysWeeks.valid) {
      return true;
    }
    return false;
  }

  saveAndContinue(): void {
    this.clientService.updateClient(this.client).subscribe(
      (res) => this.onSuccessfulSave(res),
      (err: HttpErrorResponse) => this.onFailedSaved(err),
    );
  }

  private onSuccessfulSave(res: Client): void {
    const clientId = res.id;
    this.router.navigateByUrl(`/client/${clientId}/edit/validation`);
  }

  private onFailedSaved(err: HttpErrorResponse): void {
    const serverMessage = this.clientService.cleanseError(err);
    this.messageDialog.showMessage(
      `Oops...could not save client: ${serverMessage}`,
    );
  }

  previousStep(): void {
    this.router.navigateByUrl(`/client/${this.client.id}/edit`);
  }

  private getClient(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.clientService.getClient(params['clientId']).subscribe(
        (client) => {
          this.client = Client.deserialize(client);
          this.message_expiration = this.client.message_expiration;
          this.selectedDaysWeeks.setValue(
            this.client.message_expiration.unit === 'days' ? 'Days' : this.client.message_expiration.unit === 'weeks' ? 'Weeks' : 'Days',
          );
          this.amountOfDaysWeeks.setValue(
            this.client.message_expiration.amount ?? null,
          );
        },
        (error) => {
          this.messageDialog.showMessage(
            `Oops...Could not load client: ${params['clientId']}`,
          );
        },
        () => {
          this.setPrimaryTitle();
        },
      );
    });
  }

  private setPrimaryTitle(): void {
    this.titleService.activate(
      this.client && this.client.company_name
        ? 'Configure Client - ' + this.client.company_name
        : 'Configure Client',
    );
  }
}
