import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import React from 'react';
import ReactDOM from 'react-dom';
import SignUpPagePreview from './SignUpPagePreview';
import { Client, ProductGroup } from '@app/core/models/client';
import { VerificationField } from '@app/core/models/validation-verification';

@Component({
  selector: 'sign-up-page-preview-react',
  templateUrl: './sign-up-page-preview-react-container.component.html',
  styleUrls: ['./sign-up-page-preview-react-container.component.scss'],
})
export class LoginFeedPreviewReactContainerComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @Input()
  messageText: ProductGroup['onboarding']['sign_up_page']['message_text'];
  @Input()
  ccidInputLabel: ProductGroup['onboarding']['sign_up_page']['ccid_input_label'];
  @Input()
  mobileInputLabel: ProductGroup['onboarding']['sign_up_page']['mobile_input_label'];
  @Input() validationFields: VerificationField[] = [];
  @Input() logoUrl: ProductGroup['branding']['icon_s3_url'];
  @Input() bannerUrl: ProductGroup['branding']['banner_s3_url'];
  @Input() color: ProductGroup['branding']['color'];
  @Input() productGroupName: ProductGroup['name'];
  @Input() showProductGroup: ProductGroup['hide_pg_wire_display_name'];
  @Input() productGroupDescription: ProductGroup['description'];
  @Input()
  expressWrittenConsentTsCs: ProductGroup['consent']['express_written_consent_ts_cs'];
  @Input()
  submitBtnLabel: ProductGroup['onboarding']['sign_up_page']['submit_button_label'];
  @Input()
  termsAndConditionsText: ProductGroup['onboarding']['sign_up_page']['terms_and_conditions_text'];
  @Input()
  termsAndConditionsValidationError: ProductGroup['onboarding']['sign_up_page']['terms_and_conditions_validation_error'];
  reactWrapperId: string;
  @Input() showBranding: boolean;
  @Input() hideProductGroupWireDisplayName: ProductGroup['hide_pg_wire_display_name'];

  constructor() {}

  ngOnInit() {
    this.reactWrapperId = 'sing-up-page-preview';
  }

  protected getRootDomNode() {
    const node = document.getElementById(this.reactWrapperId);
    return node;
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.getRootDomNode());
  }

  private isMounted(): boolean {
    return !!this.reactWrapperId;
  }

  ngOnChanges(): any {
    this.render();
  }

  protected render() {
    if (this.isMounted()) {
      ReactDOM.render(
        React.createElement(SignUpPagePreview, {
          messageText: this.messageText,
          ccidInputLabel: this.ccidInputLabel,
          mobileInputLabel: this.mobileInputLabel,
          validationFields: this.validationFields,
          bannerUrl: this.bannerUrl,
          logoUrl: this.logoUrl,
          color: this.color,
          expressWrittenConsentTsCs: this.expressWrittenConsentTsCs,
          submitBtnLabel: this.submitBtnLabel,
          termsAndConditionsCheckboxLabel: this.termsAndConditionsText,
          termsAndConditionsValidationErrorText:
            this.termsAndConditionsValidationError,
          showBranding: this.showBranding,
          productGroupName: this.productGroupName,
          hideProductGroupWireDisplayName: this.hideProductGroupWireDisplayName,
        }),
        this.getRootDomNode(),
      );
    }
  }
  ngAfterViewInit(): void {
    this.render();
  }
}
