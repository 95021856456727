import React, { useMemo } from 'react';
import {
  Card,
  FormFieldItem,
  HTML,
  StyleProvider,
  Header,
  BrandingData,
  defaultBrandingData,
} from '@relaynetwork/design-system';
import type { Client, ProductGroup } from '@app/core/models/client';

export type SignUpPagePreviewProps = {
  messageText: ProductGroup['onboarding']['sign_up_page']['message_text'];
  ccidInputLabel: ProductGroup['onboarding']['sign_up_page']['ccid_input_label'];
  mobileInputLabel: ProductGroup['onboarding']['sign_up_page']['mobile_input_label'];
  validationFields: Client['validation']['fields'];
  productGroupName: ProductGroup['name'];
  bannerUrl: ProductGroup['branding']['banner_s3_url'];
  logoUrl: ProductGroup['branding']['icon_s3_url'];
  color: ProductGroup['branding']['color'];
  expressWrittenConsentTsCs: ProductGroup['consent']['express_written_consent_ts_cs'];
  submitBtnLabel: ProductGroup['onboarding']['sign_up_page']['submit_button_label'];
  termsAndConditionsCheckboxLabel: ProductGroup['onboarding']['sign_up_page']['terms_and_conditions_text'];
  termsAndConditionsValidationErrorText: ProductGroup['onboarding']['sign_up_page']['terms_and_conditions_validation_error'];
  hideProductGroupWireDisplayName: ProductGroup['hide_pg_wire_display_name'];
  showBranding: boolean;
};

export default function SignUpPagePreview({
  messageText,
  ccidInputLabel,
  mobileInputLabel,
  validationFields,
  productGroupName,
  bannerUrl,
  logoUrl,
  color,
  expressWrittenConsentTsCs,
  submitBtnLabel,
  termsAndConditionsCheckboxLabel,
  termsAndConditionsValidationErrorText,
  hideProductGroupWireDisplayName,
  showBranding,
}: SignUpPagePreviewProps) {
  const defaultFieldsList: FormFieldItem[] = [
    {
      field: 'ccid',
      prompt: ccidInputLabel,
      type: 'text',
      required: true,
    },
    {
      field: 'phone_number',
      prompt: mobileInputLabel,
      type: 'phone',
      required: true,
    },
  ];

  const updatedValidationFields = validationFields.map((field) => {
    const updatedField = { ...field, required: true, type: 'text' };
    switch (field.field) {
      case 'ssn_last4':
        return { ...updatedField, type: 'ssn' };
      case 'postal_code':
        return { ...updatedField, type: 'postalcode' };
      default:
        return updatedField;
    }
  });

  const brandingData = useMemo(
    () => ({
      ...defaultBrandingData,
      color: color,
    }),
    [color],
  );

  return (
    <StyleProvider brandingData={brandingData}>
      <Header
        showRegularNavBar={true}
        showShortNavBar={!hideProductGroupWireDisplayName && showBranding}
        showMenu={false}
        showLogo={showBranding}
        pageTitle={'Sign Up'}
        showBanner={true}
        bannerUrl={bannerUrl}
        logoUrl={logoUrl}
        isMobile={true}
        isPreview={true}
        productGroupName={showBranding ? productGroupName : ''}
      />
      <div
        style={{
          marginTop: '1rem',
          marginLeft: '0',
          marginRight: '0',
        }}
      >
        <Card
          id="sign-up-page-preview"
          showHeader={true}
          variant="passwordLogin"
          isMobile={false}
          fields={[...defaultFieldsList, ...updatedValidationFields]}
          showAcceptanceOption={false}
          submitButtonLabel={submitBtnLabel}
          showTermsAndConditions={true}
          pswTCMandatory={true}
          disclaimerContent={expressWrittenConsentTsCs}
          termsAndConditionsCheckboxLabel={termsAndConditionsCheckboxLabel}
          termsAndConditionsValidationErrorText={termsAndConditionsValidationErrorText}
        >
          <div
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginBottom: '1rem',
            }}
          >
            <HTML markup={messageText ?? ''}></HTML>
          </div>
        </Card>
      </div>
    </StyleProvider>
  );
}
