<div
  class="intro-line-1"
  i18n="Introductory for creating a new client - step 2, line 1"
>
  Customize your CX Builder
</div>

<client-header
  [headerLabel]="'Portal images'"
  [headerGroup]="'portalImages'"
  (openHeader)="toggleHeader($event)"
  (closeHeader)="toggleHeader($event)"
></client-header>

<div
  class="collapse-container portal-images-container"
  *ngIf="headerGroups.portalImages && client.branding"
>
  <app-image-input
    [client]="client"
    [imgModel]="client"
    [label]="'Portal icon'"
    [type]="'icon'"
    [tipText]="'Must be at least 95px wide by 95px tall and fewer than 1MB.'"
  ></app-image-input>

  <app-image-input
    [client]="client"
    [imgModel]="client"
    [label]="'Portal banner'"
    [type]="'banner'"
    [tipText]="'Must be at least 1200px wide by 375px tall and fewer than 1MB.'"
  ></app-image-input>
</div>
<ng-container *ngIf="client">
  <client-header
    [headerLabel]="'Relay Feed'"
    [allowExpand]="false"
  ></client-header>
  <div
    class="collapse-container"
    *ngIf="headerGroups.clientID"
    style="padding-bottom: 5px"
  >
    <app-radio-btn
      *ngIf="newFeedFeatureFlagOn"
      [btnLabel]="'New Relay Feed'"
      [btnWidth]="'225px'"
      [btnName]="'new-feed'"
      [btnModel]="newFeedEnabled"
      (btnModelChange)="toggleNewFeedEnabled()"
    ></app-radio-btn>
    <span class="helper-text"
      >This will update this client to the new Feed design and enable new
      features.</span
    >
  </div>
  <div *ngIf="feedExpiredMessagesFlagOn && newFeedEnabled">
    <client-header
      [headerLabel]="'Experience Message Expiration'"
      [allowExpand]="false"
    ></client-header>
    <div
      class="collapse-container"
      *ngIf="headerGroups.clientID"
      style="margin-bottom: 0px"
    >
      <app-radio-btn
        *ngIf="feedExpiredMessagesFlagOn"
        [btnLabel]="'Enable Expiration of Feed Messages'"
        [btnWidth]="'365px'"
        [btnName]="'feed-expiration-messages'"
        [btnModel]="expiredMessagesEnabled"
        (btnModelChange)="toggleExpiredMessagesEnabled()"
      ></app-radio-btn>
      <div>
        <span class="helper-text" *ngIf="expiredMessagesEnabled"
          >Set the amount of time until this message is permanently removed
          from <br />a customer's Feed.</span
        >
      </div>
    </div>
    <div class="d-flex inputContainer" *ngIf="expiredMessagesEnabled">
      <input
        id="number-days-weeks"
        class="form-control input-number"
        [formControl]="amountOfDaysWeeks"
        name="number-days-weeks"
        placeholder="##"
        style="margin-right: 10px"
        type="number"
        pattern="[0-9]*"
        min="1"
        max="99"
        maxlength="2"
        [ngClass]="{
          'invalid-input':
            amountOfDaysWeeks.invalid &&
            (amountOfDaysWeeks.dirty || amountOfDaysWeeks.touched)
        }"
      />
      <i
        id="invalid-icon"
        *ngIf="
          amountOfDaysWeeks.invalid &&
          (amountOfDaysWeeks.dirty || amountOfDaysWeeks.touched)
        "
        class="fa fa-exclamation-circle invalid-icon"
        matTooltip="Value is required."
        [matTooltip]="
          amountOfDaysWeeks.errors?.max
            ? 'Entry cannot exceed 2 digits.'
            : 'Value is required.'
        "
        matTooltipPosition="above"
        matTooltipClass="tooltip"
      >
      </i>
      <select
        id="days-weeks-dropdown"
        class="form-control days-weeks-months-dropdown"
        [formControl]="selectedDaysWeeks"
      >
        <option *ngFor="let value of this.daysWeeks" [value]="value">
          {{ value }}
        </option>
      </select>
      <div>
        <i
          class="fa fa-question-circle question-mark-icon"
          aria-hidden="true"
          matTooltip="Does not include blackout periods."
          matTooltipPosition="above"
          matTooltipClass="tooltip"
        ></i>
      </div>
    </div>
  </div>
</ng-container>
<hr />

<div class="button-container">
  <button
    id="prev-btn"
    class="btn btn-primary btn-with-icon"
    (click)="previousStep()"
  >
    <span class="icon icon-left">
      <i class="fa fa-arrow-left"></i>
    </span>
    <span class="btn-label">Previous</span>
  </button>
  <button
    class="btn btn-primary"
    id="next-btn"
    (click)="saveAndContinue()"
    [disabled]="determineSaveButtonDisabled()"
  >
    <span
      i18n="Label for Next button on new client configuration"
      id="save-and-next-button"
    >
      Save And Next
    </span>
    <span class="icon icon-right">
      <i class="fa fa-arrow-right"></i>
    </span>
  </button>
</div>

<message-dialog></message-dialog>
<message-dialog
  #toggleOnFeedWarning
  [showCancel]="true"
  [showClose]="true"
  affirmButtonText="Enable"
  [affirmButtonClass]="'btn-primary'"
  [cancelButtonClass]="'btn-link'"
  (affirm)="closeToggleFeedDialog()"
  (cancel)="toggleNewFeedEnabled(true)"
>
  <h3>Enable New Relay Feed for {{ client ? client.company_name : '' }}?</h3>
  <p>
    This will enable the new Feed design and features for this client and their
    customers. Are you sure you want to enable the new Feed?
  </p>
</message-dialog>

<message-dialog
  #toggleOffFeedWarning
  [showCancel]="true"
  [showClose]="true"
  affirmButtonText="Disable"
  [affirmButtonClass]="'btn-primary'"
  [cancelButtonClass]="'btn-link'"
  (affirm)="closeToggleFeedDialog()"
  (cancel)="toggleNewFeedEnabled(true)"
>
  <h3>Disable New Relay Feed for {{ client ? client.company_name : '' }}?</h3>

  <p>
    This client will be reverted back to the Wire.
    <strong
      >Some features will be lost and the visual experience will change for
      their customers.</strong
    >
  </p>
  <p>Are you sure you want to disable the new Relay Feed?</p>
</message-dialog>
